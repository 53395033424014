import { navigate } from "gatsby"
import queryString from "query-string"
import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Card from "../components/shared/card"
import SearchResult from "../components/tracking/search-result"
import SearchBox from "../components/tracking/searchbox"

class TrackingPage extends Component {
  desc =
    "Knowing where your shipments are is important. Real time visibility 24/7 gives you peace of mind, anytime."
  state = { orderNum: null }
  constructor(props) {
    super(props)
    let query = this.props.location.search
      ? queryString.parse(this.props.location.search)
      : {}
    if (query.id) {
      this.state = { orderNum: query.id }
    }
  }
  componentDidUpdate(prevProps) {
    // will be true
    const locationChanged = this.props.location !== prevProps.location
    if (locationChanged) {
      let query = this.props.location.search
        ? queryString.parse(this.props.location.search)
        : {}
      if (query.id) {
        this.setState({ orderNum: query.id })
      }
    }
  }
  searchChange(value) {
    navigate("/tracking?id=" + value)
  }

  render() {
    return (
      <Layout>
        <SEO title="Tracking" description={this.desc} />
        <div className="container mx-auto md:p-6">
          <Card className="w-full bg-white rounded shadow-lg">
            <div className="px-4 py-6">
              <h3 className="font-semibold lg:font-bold text-center text-lg lg:text-3xl text-gray-800 mb-5">
                Track your Load
              </h3>
              <SearchBox searchChange={this.searchChange}></SearchBox>
              <SearchResult orderNum={this.state.orderNum}></SearchResult>
            </div>
          </Card>
        </div>
      </Layout>
    )
  }
}
export default TrackingPage
