import React, { Component } from "react"
import Button from "../shared/button"
import Input from "../shared/input"

class SearchBox extends Component {
  onFormSubmit = e => {
    e.preventDefault()
    let val = this.input.value
    this.props.searchChange(val)
  }

  render() {
    return (
      <div className="text-center">
        <form onSubmit={this.onFormSubmit}>
          <Input
            className="px-4 py-4 lg:w-1/2 focus:outline-none bg-gray-200 rounded lg:rounded-r-none hover:border-blue-400 placeholder-gray-600"
            type="text"
            placeholder="Order number /PO number / Tracking number"
            refName={input => (this.input = input)}
          />
          <Button
            className="bg-black hover:bg-gray-800 active:bg-gray-900 hover:text-gray-100 focus:outline-none text-gray-100 font-semibold text-md lg:text-lg py-4 px-4 mr-6 rounded lg:rounded-l-none"
            type="submit"
          >
            Search
          </Button>
        </form>
      </div>
    )
  }
}
export default SearchBox
