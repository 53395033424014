class Utils {
    static shortDate(val){
        if(!val) return val;
        return new Date(val).toLocaleDateString();
    }
    static shortTime(val){
        if(!val) return val;
        return new Date(val).toLocaleTimeString();
    }
    static shortDateTime(val){
        if(!val) return val;
        var d = new Date(val);
        return d.toLocaleDateString()+' '+d.getHours()+':'+d.getMinutes();
    }
    static longDate(val){
        if(!val) return val;
        var d = new Date(val);
        return d.toDateString();
    }
    static currency(val){
        if(!val)return val;
        var num =  (parseFloat(val).toFixed(2));
        num = num.replace(/\d(?=(\d{3})+\.)/g, '$&,');
        return '$'+num;
    }
    static titleCase(str){
        if(!str) return str;
        return str.replace(
            /\w\S*/g,
            function(txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    }
}
export default Utils