import axios from "axios"
import ls from "local-storage"
import Base64 from "Base64"

class LoadService {
  token = ""
  userid = ""
  constructor() {
    const usr = ls.get("wauid")
    try {
      this.token = JSON.parse(Base64.atob(usr)).Auth
      this.userid = JSON.parse(Base64.atob(usr)).Id
    } catch (err) {}
  }

  GetIp = async () => {
    const res = await axios.get("https://www.cloudflare.com/cdn-cgi/trace")
    return res.data ? res.data : null
  }
  GetLoadByOrderNumber = async orderNum => {
    const res = await axios.get(
      process.env.apiurl + "newloads/GetLoadsByOrderNumber/" + orderNum,
      { headers: { Authorization: "Basic " + this.token } }
    )
    return res.data
  }
  GetTrip = async tripNumber => {
    const res = await axios.get(
      process.env.apiurl + "newloads/GetTrip/" + tripNumber,
      { headers: { Authorization: "Basic " + this.token } }
    )
    return res.data
  }

  GetByLoadNum = async loadNum => {
    const res = await axios.get(
      process.env.apiurl + "newloads/GetLoad/" + loadNum,
      {
        headers: { Authorization: "Basic " + this.token },
      }
    )
    return res.data
  }
  GetCheckCallsLoadNum = async loadNum => {
    const res = await axios.get(
      process.env.apiurl + "checkcalls/GetCheckCallsByParent/" + loadNum,
      {
        headers: { Authorization: "Basic " + this.token },
      }
    )
    return res.data
  }
  GetDriverLastLocation = async (driverId, loadNum) => {
    const res = await axios.get(
      process.env.apiurl +
        `mobile/v1/GetDriverLastLocationByLoad/${driverId}/${loadNum}`,
      {
        headers: { Authorization: "Basic " + this.token },
      }
    )
    return res.data
  }
  GetLogsLoadNum = async loadNum => {
    const res = await axios.get(
      process.env.apiurl + "logs/GetLogsByParent/" + loadNum,
      {
        headers: { Authorization: "Basic " + this.token },
      }
    )
    return res.data
  }
  GetUserById = async id => {
    const res = await axios.get(process.env.apiurl + "Users/GetUser/" + id, {
      headers: { Authorization: "Basic " + this.token },
    })
    return res.data
  }
  GenerateSignedRateConfirmation = async data => {
    const res = await axios.post(
      process.env.pdfurl + "newloads/GenerateSignedRateConfirmation",
      data,
      {
        headers: { Authorization: "Basic " + this.token },
      }
    )
    return res.data
  }
  UpdateLoad = async updateObj => {
    const res = await axios.put(
      process.env.apiurl + "loads/UpdateLoad2",
      updateObj,
      {
        headers: { Authorization: "Basic " + this.token },
      }
    )
    return res.data
  }
  UpdateTrip = async updateObj => {
    const res = await axios.put(
      process.env.apiurl + "newloads/UpdateTrip",
      updateObj,
      {
        headers: { Authorization: "Basic " + this.token },
      }
    )
    return res.data
  }
  GetDocumentById = async id => {
    const res = await axios.get(
      process.env.apiurl + "documents/GetDocument/" + id,
      {
        headers: { Authorization: "Basic " + this.token },
      }
    )
    return res.data
  }
  UpdateDocument = async document => {
    const res = await axios.put(
      process.env.apiurl + "documents/UpdateDocument",
      document,
      {
        headers: { Authorization: "Basic " + this.token },
      }
    )
    return res.data
  }
  SendEmail = async emailObj => {
    emailObj.UserId = this.userid
    const res = await axios.post(
      process.env.pdfurl + "/newloads/EmailDocuments",
      emailObj,
      {
        headers: { Authorization: "Basic " + this.token },
      }
    )
    return res.data
  }
  AssignDriver = async tripAction => {
    const res = await axios.post(
      `${process.env.apiurl}newloads/AssignDriver`,
      tripAction,
      {
        headers: { Authorization: "Basic " + this.token },
      }
    )
    return res.data
  }

  AssignTruck = async tripAction => {
    const res = await axios.put(
      `${process.env.apiurl}newloads/AssignTruck`,
      tripAction,
      {
        headers: { Authorization: "Basic " + this.token },
      }
    )
    return res.data
  }

  AssignTrailer = async tripAction => {
    const res = await axios.put(
      `${process.env.apiurl}newloads/AssignTrailer`,
      tripAction,
      {
        headers: { Authorization: "Basic " + this.token },
      }
    )
    return res.data
  }
}
export default LoadService
