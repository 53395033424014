import React, { Component } from "react"
import ReactTooltip from "react-tooltip"
import styled from "styled-components"
import LoadService from "../../services/load-service"
import Utils from "../../services/utils"
import moment from "moment"

const ProgressContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex: 1;
  flex-direction: row;
`
const Circle = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 25px;
  display: inline-block;
  background-color: ${({ completed }) => (completed ? "#01a1e5" : "#f5f5f5")};
  position: relative;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  z-index: 1;
`
const Progress = styled.div`
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 10px;
  width: 100%;
  background: #f5f5f5;
`
const ProgressBar = styled.div`
  background-color: rgba(1, 161, 229, 1);
  width: ${({ progress }) => progress}%;
  height: 100%;
`
const StopNames = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex: 1;
  flex-direction: row;
  padding: 0;
`
const Stop = styled.span`
  margin: 0;
  display: inline-block;
  padding: 0;
  font-size: 0.8em;
  max-width: 120px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`
const TH = styled.th`
  background: #000;
  padding: 10px;
  height: 40px;
  text-align: left;
  font-size: 0.8rem;
  color: #fff;
`
const TR = styled.tr`
  font-size: 0.8rem;
  & > td {
    font-size: 0.8rem;
    padding: 10px;
  }
`
const Icon = styled.i`
  cursor: pointer;
  color: #db0606;
  font-size: 1.2rem;
`
const Check = styled.i`
  color: #ffffff;
  font-size: 1.2rem;
  display: block;
  text-align: center;
`
class Alert extends Component {
  render() {
    if (!this.props.data || !this.props.data.Notify) {
      return null
    } else {
      var tipid = "tip-" + this.props.index
      return (
        <>
          <Icon
            data-tip=""
            className="icon ion-md-alert"
            data-for={tipid}
          ></Icon>
          <ReactTooltip id={tipid}>
            <strong className="pop-title">Stop Was Adjusted</strong>
            {this.props.data.Logs.map((log, i) => {
              return (
                <div className="pop-details" key={i}>
                  {log.Description}
                </div>
              )
            })}
          </ReactTooltip>
        </>
      )
    }
  }
}
class StopDetails extends Component {
  getWeight(stop) {
    return stop.Commodities.map(e => e.Weight).reduce((a, b) => {
      return a + b !== null ? a + b : 0
    })
  }
  getUnits(stop) {
    return stop.Commodities.map(e => e.NumPieces).reduce((a, b) => {
      return a + b !== null ? a + b : 0
    })
  }
  render() {
    return (
      <>
        <table className="table-auto hidden lg:table w-full">
          <thead>
            <tr>
              <TH></TH>
              <TH>Location</TH>
              <TH>Ref #</TH>
              <TH>Date</TH>
              <TH>Appt. Time</TH>
              <TH>Arrival</TH>
              <TH>Departure</TH>
              <TH>Weight</TH>
              <TH>Units</TH>
              <TH>Stop Type</TH>
              <TH>Accessorials</TH>
            </tr>
          </thead>
          <tbody>
            {this.props.data.map((stop, i) => {
              return (
                <TR key={i}>
                  <td>
                    <Alert data={stop} index={i}></Alert>
                  </td>
                  <td>{stop.Company.Name}</td>
                  <td>
                    {stop.References.map((ref, r) => {
                      return (
                        <span key={r} className="mr-1">
                          {ref.Name}: {ref.Value}
                        </span>
                      )
                    })}
                  </td>
                  <td>{Utils.shortDate(stop.StopDate)}</td>
                  <td>{stop.Appointment || "n/a"}</td>
                  <td>{stop.Arrival ? stop.Arrival : "n/a"}</td>
                  <td>{stop.Arrival ? stop.Departure : "n/a"}</td>
                  <td>
                    {stop.Commodities.length > 0
                      ? this.getWeight(stop) + " lbs"
                      : "n/a"}
                  </td>
                  <td>
                    {stop.Commodities.length > 0
                      ? this.getUnits(stop) +
                        " " +
                        stop.Commodities[0].HandlingType
                      : "n/a"}
                  </td>
                  <td>
                    {stop.StopType
                      ? stop.StopType.replace("Delivery", "Drop")
                      : ""}
                  </td>
                  <td>
                    {stop.Invoice
                      ? stop.Invoice.map((inv, l) => {
                          return (
                            <div key={l} className="pop-details">
                              {inv.Reason +
                                ": " +
                                Utils.currency(inv.CustomerAmount)}
                            </div>
                          )
                        })
                      : ""}
                  </td>
                </TR>
              )
            })}
          </tbody>
        </table>
      </>
    )
  }
}
class CheckCalls extends Component {
  render() {
    if (!this.props.data) {
      return <></>
    }
    return (
      <>
        <h4 className="font-medium md:font-bold text-center py-10">
          Progress Details
        </h4>
        <table className="table-auto w-full">
          <thead>
            <tr>
              <TH>Date</TH>
              <TH>Time</TH>
              <TH>Location</TH>
              <TH>Details</TH>
            </tr>
          </thead>
          <tbody className="w-full">
            {this.props.data.map((call, i) => {
              return (
                <TR key={i}>
                  <td>{Utils.shortDate(call.Time)}</td>
                  <td>{Utils.shortTime(call.Time)}</td>
                  <td>{call.Location}</td>
                  <td>{call.Description}</td>
                </TR>
              )
            })}
          </tbody>
        </table>
      </>
    )
  }
}

// class Logs extends Component {

//   render() {
//     if (!this.props.data) {
//       return <></>
//     }
//     return (
//       <>
//         <h4 className="font-medium md:font-bold text-center py-10">
//           Log Details
//         </h4>
//         <table className="table-auto w-full">
//           <thead>
//             <tr>
//               <TH>Date</TH>
//               <TH>Time</TH>
//               <TH>Log Type</TH>
//               <TH>Details</TH>
//             </tr>
//           </thead>
//           <tbody className="w-full">
//             {this.props.data.map((call, i) => {
//               return (
//                 <TR key={i}>
//                   <td>{Utils.shortDate(call.Time)}</td>
//                   <td>{Utils.shortTime(call.Time)}</td>
//                   <td>{call.LogType}</td>
//                   <td>{call.Description}</td>
//                 </TR>
//               )
//             })}
//           </tbody>
//         </table>
//       </>
//     )
//   }
// }
class SearchResult extends Component {
  static checkcalls = []
  static logs = []
  static drivers = null
  state = {
    orderNumber: null,
    load: null,
    trip: null,
    progress: 0,
  }
  //progress = 0;
  load = null
  loadService = null
  constructor(props) {
    super(props)
    this.loadService = new LoadService()
    this.state.orderNumber = props.orderNum
    this.queryLoad()
  }

  static getDerivedStateFromProps(props, state) {
    if (props.orderNum !== state.orderNumber) {
      state.orderNumber = props.orderNum
      return { orderNumber: props.orderNum }
    }
    // Return null to indicate no change to state.
    return null
  }
  componentDidUpdate(prevProps) {
    if (prevProps.orderNum !== this.props.orderNum) {
      this.queryLoad()
    }
  }
  queryLoad() {
    if (this.state.orderNumber !== null) {
      this.loadService.GetLoadByOrderNumber(this.state.orderNumber).then(
        res => {
          let load = Object.assign({}, res[0].Load)
          let trip = Object.assign({}, res[0].Trip)
          this.getCheckCalls(res[0].Load)
          this.getLogs(res[0].Load)
          this.getLastLocation(res[0].Trip)
          res[0].Trip.Stops = this.initStops(res[0])
          this.setState(
            {
              orderNumber: this.props.orderNum,
              load: load,
              trip: trip,
              progress: 0,
            },
            () => {
              this.calcProgress()
            }
          )
        },
        err => {
          this.setState({ error: true, load: null })
        }
      )
    }
  }
  initStops(extendedLoad) {
    const companies = extendedLoad.TripCompanies
    const stops = extendedLoad.Trip.Stops
    const invoice = extendedLoad.Invoices
    return stops.map(e => {
      e.Company = companies.find(x => x.id === e.CompanyId)
      if (invoice && invoice.length > 0) {
        let stopInvoices = invoice.filter(i => {
          return i.CustomerAmount > 0 && i.Stop && i.Stop === e.Company.Name
        })
        if (stopInvoices.length > 0) {
          e.Invoice = stopInvoices
        }
      }
      return e
    })
  }
  async getCheckCalls(load) {
    this.checkcalls = await this.loadService.GetCheckCallsLoadNum(
      load.LoadNumber
    )
    if (this.checkcalls) {
      let checkcall = Object.assign({}, this.state)
      checkcall["checkcalls"] = this.checkcalls
      this.setState(checkcall)
    }
  }
  async getLastLocation(trip) {
    if (trip.Drivers && trip.Drivers.length > 0) {
      this.drivers = await this.loadService.GetDriverLastLocation(
        trip.Drivers[0].Id,
        trip.LoadNumber
      )
      if (this.drivers) {
        let driver = Object.assign({}, this.state)
        driver["drivers"] = this.drivers
        this.setState(driver)
      }
    }
  }
  async getLogs(load) {
    this.logs = await this.loadService.GetLogsLoadNum(load.LoadNumber)
    if (this.logs) {
      let log = Object.assign({}, this.state)
      log["logs"] = this.logs
      this.setState(log)
    }
  }
  getChanges(stops, logs) {
    return stops.map(e => {
      if (e.StopId) {
        let logsToShow = logs.filter(l => {
          return l.AssociateWith === e.StopId && l.NotifyCustomer === true
        })
        if (logsToShow.length > 0) {
          e.Notify = true
          e.Logs = []
          for (let i = logsToShow.length - 1; i >= 0; i--) {
            let l = logsToShow[i]
            let desc =
              l.FieldName +
              (l.OldValue ? " changed from " + l.OldValue : " set") +
              " to " +
              l.NewValue +
              (l.Reason ? " Reason: " + l.Reason : "")
            if (e.Logs.findIndex(ol => ol.FieldName === l.FieldName) === -1) {
              e.Logs.push({
                Id: l.Id,
                FieldName: l.FieldName,
                Description: desc,
              })
            }
          }
        }
      }
      return e
    })
  }

  calcProgress() {
    if (
      this.state.load.Status === "Open" ||
      this.state.load.Status === "Covered"
    ) {
      let newState = Object.assign({}, this.state)
      newState["progress"] = 0
      this.setState(newState)
    } else if (this.state.load.Status === "Completed") {
      let newState = Object.assign({}, this.state)
      newState["progress"] = 100
      this.setState(newState)
    } else {
      let completedstops = this.state.trip.Stops.filter(stop => {
        return stop.Status !== "Covered"
      })
      let progress =
        (completedstops.length / (this.state.trip.Stops.length + 1)) * 100
      let newState = Object.assign({}, this.state)
      newState["progress"] = progress
      this.setState(newState)
    }
  }
  getStopCoordinates(stops) {
    return stops.map(e => {
      let obj = {
        name: e.Company.Name,
        Coordinates: e.Company.PhysicalAddress.Coordinates,
      }
      return obj
    })
  }
  render() {
    if (!this.state.load) {
      return (
        <>
          {this.state.error ? (
            <div className="flex justify-center pt-8">
              <div
                class="flex items-center bg-red-600 text-white text-sm font-bold px-4 py-3 rounded-lg"
                role="alert"
              >
                <svg
                  class="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
                </svg>
                <p>
                  Sorry, we couldn't find any loads matching that order number.
                </p>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </>
      )
    } else {
      return (
        <div>
          <h4 className="text-center font-medium py-5">
            Order #: {this.state.orderNumber}
          </h4>
          {this.state.drivers &&
          (this.state.load.Status === "Covered" ||
            this.state.load.Status === "In Transit") ? (
            <h3 className="text-center font-medium text-lg py-5">
              Driver last known location since <br />
              {moment(this.state.drivers.Timestamp).format(
                "MMMM DD YYYY HH:mm A"
              )}{" "}
              <br /> <b>{this.state.drivers.CurrentAddress}</b>
            </h3>
          ) : null}
          <h4 className="py-5 font-medium text-center">Shipment Progress</h4>
          <ProgressContainer>
            <Circle
              completed={this.state.load.Status === "Open" ? false : true}
            >
              {this.state.load.Status !== "Open" ? (
                <Check className="icon ion-md-checkmark"></Check>
              ) : (
                <></>
              )}
            </Circle>

            {this.state.trip.Stops.map(function(stop, i) {
              return (
                <Circle
                  key={i}
                  completed={
                    stop.Status !== "Open" && stop.Status !== "Covered"
                      ? true
                      : false
                  }
                >
                  {stop.Status !== "Covered" && stop.Status !== "Open" ? (
                    <Check className="icon ion-md-checkmark"></Check>
                  ) : (
                    <></>
                  )}
                </Circle>
              )
            })}

            <Circle
              completed={this.state.load.Status === "Completed" ? true : false}
            >
              {this.state.load.Status === "Completed" ? (
                <Check className="icon ion-md-checkmark"></Check>
              ) : (
                <></>
              )}
            </Circle>
            <Progress>
              <ProgressBar progress={this.state.progress}></ProgressBar>
            </Progress>
          </ProgressContainer>
          <StopNames>
            <Stop>
              <strong>Dispatched</strong>
            </Stop>
            {
              //length=this.state.load.Trips[0].Stops.length
            }
            {this.state.trip.Stops.map(function(stop, i) {
              return (
                <Stop key={i}>
                  <strong title={Utils.titleCase(stop.Company.Name)}>
                    {Utils.titleCase(stop.Company.Name)}
                  </strong>
                </Stop>
              )
            })}
            <Stop>
              <strong>Invoiced</strong>
            </Stop>
          </StopNames>
          {/*  */}
          <h4 className="font-medium lg:font-bold hidden lg:block text-center py-10">
            Stop Details
          </h4>
          <StopDetails data={this.state.trip.Stops}></StopDetails>
          {!this.state.drivers &&
          this.state.checkcalls &&
          this.state.checkcalls.length > 0 ? (
            <CheckCalls data={this.state.checkcalls}></CheckCalls>
          ) : null}
          {/* <Logs data={this.state.logs}></Logs> */}
          {/* <MapContainer stops={this.getStopCoordinates(this.state.load.Trips[0].Stops)} location={this.state.drivers}></MapContainer> */}
        </div>
      )
    }
  }
}
export default SearchResult
